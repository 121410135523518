<template>
  <div class="page">
    <div class="card">
      <div class="text">
        <h1>登录</h1>
      </div>
      <van-form @submit="onSubmit">
  <van-field
    v-model="uname"
    name="用户名"
    label="用户名"
    placeholder="用户名"
    :rules="[{ required: true, message: '请填写用户名' }]"
  />
  <van-field
    v-model="pwd"
    type="password"
    name="密码"
    label="密码"
    placeholder="密码"
    :rules="[{ required: true, message: '请填写密码' }]"
  />
  <div style="margin: 16px;">
    <van-button round block type="info" native-type="submit">提交</van-button>
  </div>
</van-form>
    </div>
  </div>
</template>

<script>
import {Toast} from 'vant'
export default {
  data(){
    return{
      uname:'',
      pwd:''
    }
    },
    // computed:{
    //   uname(){
    //     return this.$cookies.get('uname')
    //   },
    //   pwd(){
    //     return this.$cookies.get('pwd')
    //   }
    // },
    methods: {
    async onSubmit(){
     let res = await  this.$api.Login({username:this.uname,password:this.pwd})
      if(!res.data.code){
        Toast(res.data.msg)
        return
      }
      if(res.data.code=="200"){
        // console.log(res.data.data)
        this.$store.state.token = res.data.data.token
        this.$store.state.username = res.data.data.userName
        this.$cookies.set('token',res.data.data.token)
        this.$cookies.set('userName',res.data.data.userName)
        // this.$cookies.set('uname',this.uname)
        // this.$cookies.set('pwd',this.pwd)
        localStorage.setItem('uname',this.uname)
        localStorage.setItem('pwd',this.pwd)
        Toast('登陆成功')
        setTimeout(() => {
          this.$emit('goSearch')
        }, 500);
      }

    }
  },
  created(){
    this.uname = localStorage.getItem('uname')
    this.pwd = localStorage.getItem('pwd')

  }
}
</script>

<style lang="less" scoped>
.page{
  background:url('../../assets/carbg1.jpg');
  width:100%;
  height: 100%;
  position: fixed;
  background-size: 100% 90%;
  text-align: center;
  .card{
    width: 80%;
    height: 30%;
    margin: 25% auto;
    // border:1px solid #000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text{
      color:#1989fa;
    }
  }
}
.van-form {
      margin-top: 50px;
      border-radius: 25px;
      // .van-cell{
      //   border-radius: 25px;
      // }
}
</style>