/*
公共的请求方法
*/
import base from './base'
import axios from '@/utils/request'
const api = {
    /**
     * 登录
     */
    Login(param){
        let customConfig = {
            headers:{
                'Content-Type': 'application/json'
            }
        }
        return axios.post(base.login,param,customConfig)
            
    },
    Search(param,token){
        let customConfig = {
            headers:{
                'Content-Type': 'application/json',
                'Merchanttoken':token
            }
        }
        return axios.post(base.SearchUrl,param,customConfig) 
    },
    Detail(param,token){
        let customConfig = {
            headers:{
                'Content-Type': 'application/json',
                'Merchanttoken':token
            }
        }
        return axios.post(base.detailUrl,param,customConfig) 
    },
    getCard(token){
        let customConfig = {
            headers:{
                'Content-Type': 'application/json',
                'Merchanttoken':token
            }
        }
        return axios.post(base.cardUrl,{},customConfig) 
    },
    Send(param,token){
        let customConfig = {
            headers:{
                'Content-Type': 'application/json',
                'Merchanttoken':token
            }
        }
        return axios.post(base.sendUrl,param,customConfig) 
    },
}

export default api