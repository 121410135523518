<template>
  <div class="home">
    <!-- 公共的顶部logo -->
    <div class="header" :style="`background:rgba(${hstyle.r},${hstyle.g},${hstyle.b})`">
      
      <img src="@/assets/hg.png">
      <div class="time">
        <!-- <span>{{nyr}}</span> -->
        <span>{{nowtime | updatetime}}</span>
        </div>
    </div>
    
    <!-- 选项卡 -->
    <van-tabs v-model="active">
      <van-tab title="音乐欣赏" name='1'>
      <Music></Music>
  </van-tab>
      <van-tab title="发停车券" name='2'>
      <car-login v-show="isnLogin" @goSearch="goSearch"></car-login>
      <car-search v-show="isSearch" @goDtail="goDtail" @logOut="logOut" @toLogin="toLogin" ></car-search>
      <car-detail v-show="isDetail" @goSearch="goSearch" @toLogin="toLogin"></car-detail>
  </van-tab>
  
</van-tabs>
  </div>
</template>

<script>
import Music from './room/Music.vue'
import CarSearch from './car/CarSearch.vue'
import CarLogin from './car/CarLogin.vue'
import CarDetail from './car/CarDetail.vue'
import moment from 'moment'
moment.locale('zh-cn',{
  weekdays:'星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_')
})
export default {
  components:{
    Music,
    CarSearch,
    CarLogin,
    CarDetail
    
  },
  data(){
    return {
      active:1,
      isnLogin:true,
      isSearch:false,
      isDetail:false,
      info:{
      token:'',
      username:'',
    },
    hstyle:{
        r:Math.floor(Math.random()*255),
        g:Math.floor(Math.random()*255),
        b:Math.floor(Math.random()*255),
        a:Math.random()
      },
    nowtime:new Date()
    // detailInfo:{}
    }
  },
  filters:{
    updatetime:(value)=>{
      return moment(value).format('YYYY年MM月DD日 dddd   HH:mm:ss')
    }
  },
  // computed:{
  //   nyr(){
  //     return  moment().format('YYYY年MM月DD日 星期E')
  //   }
  // },
  mounted(){
    let _this  = this
    this.timer = setInterval(()=>{
      _this.nowtime = new Date()
    },1000)
  },
  beforeDestroy(){
    if(this.timer){
      clearInterval(this.timer)
    }
  },
  created(){
    if(this.$cookies.isKey('token')){
      this.$store.state.token = this.$cookies.get('token')
      this.$store.state.username = this.$cookies.get('userName')
      this.goSearch()
    }else{
      this.toLogin()
    }
  },
  methods:{
    goDtail(){
      // this.detailInfo = val
      this.isnLogin = false
      this.isSearch = false
      this.isDetail = true
    },
    goSearch(){
      this.isnLogin = false
      this.isSearch = true
      this.isDetail = false
    },
    logOut(){
      this.$store.state.token = ''
      this.$cookies.remove('token')
      this.isnLogin = true
      this.isSearch = false
      this.isDetail = false
    },
    toLogin(){
      this.isnLogin = true
      this.isSearch = false
      this.isDetail = false
    },
  }
}
</script>

<style lang='less' scoped>
.home{
  background:url('../assets/home.jpg');
  width:100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
}
.header{
  display: flex;
  justify-content: space-between;
  // background:#1eaceb;
  // background:#24acf2;

  height: 1.38rem;
  line-height: 1.38rem;
  img{
    height: 18vw;
    padding: 0.1rem;
    // margin-top: 0.1rem;
  }
  .time{
    width: 260vw;
    // padding-right: 5px;
    // margin-right: 5vw;
    // margin-top: 20px;
    color:#fff;
    font-size: 16px;
    font-weight: 700;
    span{
      margin-left: 5vw;
      padding: 0.2rem;
    }
  }


}
/deep/ .van-tabs--line .van-tabs__wrap{
  border-bottom: 1px solid #dbdbdb;
  .van-tab{
    font-size:18px;
  }
}
</style>